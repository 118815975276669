import React from 'react'

const Adversitement = () => {
  return (
   <>
   <div>
 {/* Ads Start */}
<div className="mb-3">
  <div className="section-title mb-0">
    <h4 className="m-0 text-uppercase font-weight-bold">Advertisement</h4>
  </div>
  <div className="bg-white text-center border border-top-0 p-3">
    <a href><img className="img-fluid" src="../assets/img/news-800x500-2.jpg" alt /></a>
  </div>
</div>
{/* Ads End */}

   </div>
   </>
  )
}

export default Adversitement
