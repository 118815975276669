import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';

const FeaturedNews = () => {
    const [carouselData, setCarouselData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [feedName, setFeedName] = useState("");
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      };

    useEffect(() => {
        const fetchFeaturedBlogs = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BLOG_URL}home-data`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();
                console.log('API Response:', responseData);
                
                // Find the object in the blog array where feedposition is 1
                const carouselBlog = responseData.ResponseData.blog.find(blog => blog.feedposition === 1);
                console.log('Carousel Blog:', carouselBlog);
                
                // Check if carouselBlog is not undefined before accessing its properties
                if (carouselBlog) {
                    setCarouselData(carouselBlog.feedwiseblog);
                    setFeedName(carouselBlog.feedname); // Set the feed name
                } else {
                    throw new Error('Carousel blog data not found');
                }
                
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        fetchFeaturedBlogs();
    }, []);

    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }

    // if (error) {
    //     return <div>Error: {error.message}</div>;
    // }
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
      };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    return (
        <div className="container-fluid py-5  px-xl-5">
      
            <div className="section-title mb-0">
                <h4 className="m-0 text-uppercase font-weight-bold">{feedName}</h4>
            </div>
            <Slider {...settings} className="mt-5">
            {Object.values(carouselData).map(blog => (
                <div key={blog.id}>
                        <div className="position-relative overflow-hidden" style={{ height: 300, marginRight: 10, marginLeft: 10 }}>
                            <img className="img-fluid h-100" src={blog.image} alt="Slide" style={{ objectFit: 'cover' }} />
                            <div className="overlay">
                                <div className="mb-2">
                                    <a className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" href="">{blog.category_name}</a>
                                    <a className="text-white" href=""><small>{formatDate(blog.created_at)}</small></a>
                                </div>
                                <a className="h6 m-0 text-white text-uppercase font-weight-semi-bold pointer" onClick={() => {navigate(`/productdetail/${blog.slug}`); scrollToTop();}}  >{blog.shortdescription. slice(0, 40) + '...'}</a>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>  
        
    </div>
    );
};

export default FeaturedNews;
