import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Pages/Home/Home';
import Footer from './Components/Layout/Footer/Footer';
import Topbar from './Components/Layout/Topbar/Topbar';
import Navbar from './Components/Layout/Navbar/Navbar';
import ProductDetail from './Components/Pages/ProductDetail/ProductDetail';
import Advrtise from './Components/Pages/Advrtise/Advrtise';
import Category from './Components/Pages/Category/Category';
import { useParams } from 'react-router-dom';

function App() {

  
  return (
   <>
   <Topbar />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Route for product detail with slug */}
        <Route path="/productdetail/:slug" element={<ProductDetail />} />
        <Route path="/adv" element={<Advrtise />} />
        <Route path="/category" element={<Category />} />
      </Routes>
      <Footer />

   </>
  );
}

export default App;
