import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MiddleBar from '../MiddleBar/MiddleBar';

const Navbar = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <>
      {/* Navbar Start */}
      <div className="container-fluid ">
   
        <div className="row align-items-center bg-white  py-md-0 py-sm-0 px-lg-5 blackbg ">
          <div className="col-lg-3">
            <a href="/" className="navbar-brand p-0 d-none d-lg-block">
              <h1 className="m-0 display-4 text-uppercase text-primary">Biz<span className="text-secondary font-weight-normal">News</span></h1>
            </a>
          </div>
       
     <div className='col-lg-9'>
        <nav className="navbar navbar-expand-lg  navbar-dark py-2  py-lg-0 px-lg-5">
          <a onClick={() => { navigate('/'); scrollToTop(); }} className="navbar-brand d-block d-lg-none">
            <h1 className="m-0 display-4 text-uppercase text-primary">Biz<span className="text-black font-weight-normal news">News</span></h1>
          </a>
          <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse justify-content-between  px-lg-3 bg-white" id="navbarCollapse">
            <div className="navbar-nav mr-auto py-0">
              <a onClick={() => { navigate('/'); scrollToTop(); }} className="nav-item nav-link pointer">Home</a>
              <a onClick={() => { navigate('/category'); scrollToTop(); }} className="nav-item nav-link pointer">Category</a>
         
              <a onClick={() => { navigate('/'); scrollToTop(); }} className="nav-item nav-link pointer">Contact</a>
            </div>
        <MiddleBar/>
          </div>
        </nav>
        </div>
        </div>
      </div>
      {/* Navbar End */}
    </>
  );
};

export default Navbar;
