import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const MiddleBar = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearchResults, setShowSearchResults] = useState(true);
  const navigate = useNavigate();

  const handleSearch = async (query) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BLOG_URL}search-blog?search=${query}`);
      if (response.data.ResponseCode === 1) {
        setSearchResults(response.data.ResponseData || []);
      } else {
        console.error('Error fetching search results:', response.data.ResponseText);
        setSearchResults([]);
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
      setSearchResults([]);
    }
  };
  const handleChange = async (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    if (value.trim() !== '') {
      await handleSearch(value);
      setShowSearchResults(true); // Show search results when there's input
    } else {
      setSearchResults([]);
      setShowSearchResults(false); // Hide search results when input is empty
    }
  };
  const handleResultClick = (blog) => {
    navigate(`/productdetail/${blog.slug}`);
    setShowSearchResults(false);    
  };
  
  return (
    <>
      <div className="input-group ml-auto d-none d-lg-flex" style={{ width: '100%', maxWidth: 300 }}>
     
        <input
          type="text"
          className="form-control border-0"
          placeholder="Keyword"
          value={searchQuery}
          onChange={handleChange}
        />
         <div className="input-group-append">
          <button className="input-group-text bg-primary text-dark border-0 px-3"><i className="fa fa-search" /></button>
        </div>
        {showSearchResults && (
        <div className='search-result position-absolute w-100 mt-5' style={{ zIndex: 1 }}>
          <ul className='bg-white'>
            {searchResults.map(blog => (
              <li key={blog.id} onClick={() => handleResultClick(blog)} className='pointer'>                         
                <p>{blog.shortdescription}</p>             
              </li>
            ))}
          </ul>
        </div>
      )}
      </div>
       
   
    </>
  )
}

export default MiddleBar
