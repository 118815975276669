import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Follow from '../Home/Follow/Follow';
import { Adversitement } from '../Home/Adversitement';
import { TrandingNews } from '../Home/TrandingNews';
import { Tags } from '../Home/Tags';
import { NewsLetter } from '../Home/NewsLetter';
import style from './Category.module.css';
import { useNavigate } from 'react-router-dom';

const Category = () => {
    const [selectedCategory, setSelectedCategory] = useState('Business');
    const [categoryList, setCategoryList] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const navigate = useNavigate();
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BLOG_URL}category-list`)
            .then(response => {
                setCategoryList(response.data.ResponseData);
                const defaultCategory = response.data.ResponseData.find(category => category.name === 'Business');
                if (defaultCategory) {
                    setSelectedCategoryId(defaultCategory.id);
                }
            })
            .catch(error => {
                console.error('Error fetching category list:', error);
            });
    }, []);

    useEffect(() => {
        async function fetchBlogList() {
            if (selectedCategoryId) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BLOG_URL}category-blog/${selectedCategoryId}`);
                    if (response.data.ResponseCode === 1) {
                        setBlogs(response.data.ResponseData.blog);
                    } else {
                        console.error('Error fetching blog list:', response.data.ResponseText);
                    }
                } catch (error) {
                    console.error('Error fetching blog list:', error);
                }
            }
        }
        fetchBlogList();
    }, [selectedCategoryId]);

    const handleCategorySelection = (categoryId) => {
        setSelectedCategory(categoryList.find(category => category.id === categoryId)?.name || 'Business');
        setSelectedCategoryId(categoryId);
    };
    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      };
    return (
        <>
            <div className="container-fluid mt-xl-5 pt-3 px-xl-5">
                <div className="row d-flex justify-content-between">
                    <div className="col-xl-2 col-lg-3 col-md-4">
                        <div>
                            <h4>Filter by Category</h4>
                            <ul style={{ listStyleType: 'none', padding: 0, display: 'flex', flexDirection: 'column' }}>
                                {categoryList.map(category => (
                                    <li
                                        key={category.id}
                                        onClick={() => handleCategorySelection(category.id)}
                                        className={selectedCategory === category.name ? style.categoryStyleActive : style.categoryStyle}
                                    >
                                        {category.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-8">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-title">
                                    <h4 className="m-0 text-uppercase font-weight-bold">Category: {selectedCategory}</h4>
                                </div>
                            </div>
                            {blogs.length === 0 ? (
                                <div className="col-lg-12 ">
                                    <div className='d-flex align-items-center justify-content-center w-100'>

                                        <img src="../assets/img/26685.png" alt="" />
                                    </div>
                                </div>
                            ) : (
                                blogs.map(blog => (
                                    <div className="col-lg-6" key={blog.id}>
                                        <div className="position-relative mb-3">
                                            <div className={style.categoryImage}>
                                                <img className={style.categoryImagee} src={blog.image} alt={blog.category_name} />
                                            </div>
                                            <div className="bg-white border border-top-0 p-4">
                                                <div className="mb-2">
                                                    <a className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2" href>{blog.category_name}</a>
                                                    <a className="text-body" href><small>{formatDate(blog.created_at)}</small></a>
                                                </div>
                                                <a className="h4 d-block mb-3 text-secondary text-uppercase font-weight-bold pointer"
                                                 onClick={() => {
                                                    navigate(`/productdetail/${blog.slug}`);
                                                    scrollToTop();
                                                  }}
                                                 >
                                                    {blog.shortdescription.slice(0, 45)}</a>
                                                <div dangerouslySetInnerHTML={{ __html: blog.description.slice(0, 150) }} />
                                            </div>
                                            <div className="d-flex justify-content-between bg-white border border-top-0 p-4">
                                                <div className="d-flex align-items-center">
                                                    <img className="rounded-circle mr-2" src="../assets/img/user.jpg" width={25} height={25} alt="User" />
                                                    <small>John Doe</small>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <small className="ml-3"><i className="far fa-eye mr-2" />{blog.views}</small>
                                                    <small className="ml-3"><i className="far fa-comment mr-2" />{blog.comment_count}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Category;
