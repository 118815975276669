
import { Adsense } from '@ctrl/react-adsense'
import React from 'react'

const Advrtise = () => {
  return (
  <>
     <div>
     <Adsense
  client="ca-app-pub-3940256099942544"
  slot="2934735716"
/>


<Adsense
  client="ca-app-pub-3940256099942544"
  slot="2934735716"
  style={{ width: 500, height: 300 }}
  format=""
/>

<Adsense
  client="ca-app-pub-3940256099942544"
  slot="2934735716"
  style={{ display: 'block' }}
  layout="in-article"
  format="fluid"
/>
    </div>
  </>
  )
}

export default Advrtise
