import React, { useState } from 'react';
import axios from 'axios';

const NewsLetter = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSignUp = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BLOG_URL}newsletter-add`, { email });
      if (response.data.ResponseCode === 1) {
        setMessage('Newsletter added successfully');
        setEmail(''); // Clear the input field
      } else {
        setMessage('Failed to add newsletter. Please try again.');
      }
    } catch (error) {
      console.error('Error signing up for newsletter:', error);
      setMessage('Failed to add newsletter. Please try again.');
    }
  };
console.log(email);
  return (
    <div>
      {/* Newsletter Start */}
      <div className="mb-3">
        <div className="section-title mb-0">
          <h4 className="m-0 text-uppercase font-weight-bold">Newsletter</h4>
        </div>
        <div className="bg-white text-center border border-top-0 p-3">
          <p>Aliqu justo et labore at eirmod justo sea erat diam dolor diam vero kasd</p>
          <div className="input-group mb-2" style={{ width: '100%' }}>
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn btn-primary font-weight-bold px-3" onClick={handleSignUp}>Subscribe</button>
            </div>
          </div>
          <small>{message}</small>
        </div>
      </div>
      {/* Newsletter End */}
    </div>
  );
};

export default NewsLetter;
