import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const TrandingNews = () => {
  const [trendingData, setTrendingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [feedName, setFeedName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchTrendingData = async () => {
      try {
        // Fetch trending news data from the API
        const response = await fetch(`${process.env.REACT_APP_BLOG_URL}home-data`);
        if (!response.ok) {
          throw new Error('Failed to fetch trending data');
        }
        const responseData = await response.json();
        console.log('API Response:', responseData);

        // Extract trending blog data
        const trendingBlog = responseData.ResponseData.blog.find(blog => blog.feedposition === 3);
        console.log('Trending Blog:', trendingBlog);
        if (!trendingBlog) {
          throw new Error('Trending blog data not found');
        }

        setTrendingData(Object.values(trendingBlog.feedwiseblog));
        setFeedName(trendingBlog.feedname);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchTrendingData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };
  return (
    <>

      <div class="mb-3">
        <div className="section-title mb-0">
          <h4 className="m-0 text-uppercase font-weight-bold">{feedName}</h4>
        </div>
        <div className="bg-white border border-top-0 p-3">
          {trendingData.map(blog => (
            <div className="d-flex align-items-center bg-white mb-3" style={{ height: '110px' }}>
              <img className="img-fluid" src={blog.image} style={{ width: '120px', height: '110px' }} alt />
              <div className="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0">
                <div className="mb-2">
                  <a className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" >{blog.category_name}</a>
                  <a className="text-body" href><small>{formatDate(blog.created_at)}</small></a>
                </div>
                <a className="h6 m-0 text-secondary text-uppercase font-weight-bold pointer"  onClick={() => { navigate(`/productdetail/${blog.slug}`); scrollToTop(); }}>{blog.shortdescription}</a>
              </div>
            </div>
          ))}
        </div>
      </div>


    </>
  )
}

export default TrandingNews
