import React, { useState } from 'react';
import axios from 'axios';

const Comment = ({ blogId, updateComments }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    website: '',
    msg: '',
    blog_id: blogId,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BLOG_URL}comment-add`, formData);
      console.log('Comment added successfully:', response.data);
      // Update the comment list by calling the updateComments function passed from the parent component
      updateComments();
      setFormData({
        name: '',
        email: '',
        website: '',
        msg: '',
        blog_id: blogId,
      });
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

    return (
        <>
            <div className="mb-3">
                <div className="section-title mb-0">
                    <h4 className="m-0 text-uppercase font-weight-bold">Leave a comment</h4>
                </div>
                <div className="bg-white border border-top-0 p-4">
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name">Name *</label>
                                    <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="email">Email *</label>
                                    <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="website">Website</label>
                            <input type="url" className="form-control" id="website" name="website" value={formData.website} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message *</label>
                            <textarea id="msg" cols={30} rows={5} className="form-control" name="msg" value={formData.msg} onChange={handleChange} />
                        </div>
                        <div className="form-group mb-0">
                            <input type="submit" value="Leave a comment" className="btn btn-primary font-weight-semi-bold py-2 px-3" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Comment;
