import React from 'react'
import LatestNews from './LetestNews/LatestNews'
import FeaturedNews from './FeaturedNews/FeaturedNews'
import MainCaresouel from './MainCaresouel/MainCaresouel'
import BreakingNews from './BreakingNews/BreakingNews'
import Advrtise from '../Advrtise/Advrtise'

const Home = () => {
  return (
 <>
 <MainCaresouel />
 <BreakingNews />
 <FeaturedNews />
 {/* <Advrtise/> */}
 <LatestNews />
 </>
  )
}

export default Home
