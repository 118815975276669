import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import style from './MainCaresouel.module.css';


const MainCaresouel = () => {
  const navigate = useNavigate();

  /*-----------------banner-----------------*/
  const [bannerData, setBannerData] = useState([]);

  useEffect(() => {
    async function fetchBannerData() {
      try {
        const response = await axios.get(process.env.REACT_APP_BLOG_URL + 'home-data');
        if (response.data.ResponseCode === 1) {
          setBannerData(response.data.ResponseData.banner);
        } else {
          console.error('Error fetching data:', response.data.ResponseText);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchBannerData();
  }, []);

  /*-----------------home banner-----------------*/
  const [homeBannerData, setHomeBannerData] = useState([]);
  useEffect(() => {
    async function fetchHomeBannerData() {
      try {
        const response = await axios.get(process.env.REACT_APP_BLOG_URL + 'home-data');
        if (response.data.ResponseCode === 1) {
          // Format the created_at date
          const formattedData = response.data.ResponseData.homebanner.map(banner => ({
            ...banner,
            created_at: formatDate(banner.created_at)
          }));
          setHomeBannerData(formattedData);
        } else {
          console.error('Error fetching data:', response.data.ResponseText);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchHomeBannerData();
  }, []);

  // Function to format the date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };
  return (
    <>
      {/* Main News Slider Start */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-7 px-0">
            <div id="main-carousel" className="carousel slide position-relative" data-ride="carousel">
              <div className="carousel-inner">
                {bannerData.map((banner, index) => (
                  <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={banner.id}>
                    <div className={style.mainBanner}>
                      <img className='w-100 h-100 img-fluid ' src={banner.image} style={{ objectFit: 'cover' }} alt={banner.title} />
                    </div>
                    <div className="overlay">
                      <div className="mb-2">
                        <a className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                        // href={banner.link}
                        >Business</a>
                        <a className="text-white" >{formatDate(banner.created_at)}</a>
                      </div>
                      <a className="h2 m-0 text-white text-uppercase font-weight-bold pointer" onClick={() => navigate(`/productdetail/${banner.slug}`)}>{banner.title}</a>
                    </div>
                  </div>
                ))}
              </div>
              <ol className="carousel-indicators">
                {bannerData.map((banner, index) => (
                  <li key={index} data-target="#main-carousel" data-slide-to={index} className={index === 0 ? 'active' : ''}></li>
                ))}
              </ol>
              {/* <div >
                                                   <a className="carousel-control-prev " href="#main-carousel" role="button" data-slide="prev">
                                <span className="yellowcareousel-control "  aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#main-carousel" role="button" data-slide="next">
                                <span className="yellowcareousel-control " style={{ marginLeft: 30 }} aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                                                   </div> */}
            </div>
          </div>
          <div className="col-lg-5 px-0">
            <div className="row mx-0">
              {homeBannerData.map(banner => (
                <div className="col-md-6 px-0" key={banner.id}>
                  <div className="position-relative overflow-hidden" style={{ height: 250 }}>
                    <img className="img-fluid w-100 h-100" src={banner.image} style={{ objectFit: 'cover' }} alt={banner.title} />
                    <div className="overlay">
                      <div className="mb-2">
                        <a className="badge badge-primary text-uppercase font-weight-semi-bold p-2 mr-2"
                        //  href={banner.link}
                        >Business</a>
                        <a className="text-white" href="#"><small>{banner.created_at}</small></a>
                      </div>
                      <a className="h6 m-0 text-white text-uppercase font-weight-semi-bold pointer" onClick={() => navigate(`/productdetail/${banner.slug}`)}>{banner.title}</a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainCaresouel
