import React from 'react';

const BreakingNews = () => {
    return (
        <div id="breaking-news-carousel" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div className="container-fluid bg-dark py-3 mb-3">
                        <div className="container">
                            <div className="row align-items-center bg-dark">
                                <div className="col-12">
                                    <div className="d-flex justify-content-between">
                                        <div className="bg-primary text-dark text-center font-weight-medium py-2" style={{ width: 170 }}>Breaking News</div>
                                        <div className="owl-carousel tranding-carousel position-relative d-inline-flex align-items-center ml-3" style={{ width: 'calc(100% - 170px)', paddingRight: 90 }}>
                                            <div className="text-truncate"><a className="text-white text-uppercase font-weight-semi-bold" href>Lorem ipsum dolor sit amet elit. Proin interdum lacus eget ante tincidunt, sed faucibus nisl sodales</a></div>
                                            <div className="text-truncate"><a className="text-white text-uppercase font-weight-semi-bold" href>Lorem ipsum dolor sit amet elit. Proin interdum lacus eget ante tincidunt, sed faucibus nisl sodales</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item ">
                    <div className="container-fluid bg-dark py-3 mb-3">
                        <div className="container">
                            <div className="row align-items-center bg-dark">
                                <div className="col-12">
                                    <div className="d-flex justify-content-between">
                                        <div className="bg-primary text-dark text-center font-weight-medium py-2" style={{ width: 170 }}>Breaking News</div>
                                        <div className="owl-carousel tranding-carousel position-relative d-inline-flex align-items-center ml-3" style={{ width: 'calc(100% - 170px)', paddingRight: 90 }}>
                                            <div className="text-truncate"><a className="text-white text-uppercase font-weight-semi-bold" href>Lorem ipsum dolor sit amet elit. Proin interdum lacus eget ante tincidunt, sed faucibus nisl sodales</a></div>
                                            <div className="text-truncate"><a className="text-white text-uppercase font-weight-semi-bold" href>Lorem ipsum dolor sit amet elit. Proin interdum lacus eget ante tincidunt, sed faucibus nisl sodales</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Additional carousel items go here */}
            </div>
            <a className="carousel-control-prev " href="#breaking-news-carousel" role="button" data-slide="prev" >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#breaking-news-carousel" role="button" data-slide="next">
                <span className="carousel-control-next-icon  " aria-hidden="true"  ></span>
                <span className="sr-only">Next</span>
            </a>
        </div>
    );
}

export default BreakingNews;
