import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Follow = () => {
  const [followers, setFollowers] = useState([]);
  const colors = [ '#C8359D','#39569E', '#52AAF4' ,'#0185AE','#2EAE1E','#DC472E'];

  useEffect(() => {
    const fetchFollowers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BLOG_URL}home-data`);
        if (response.data.ResponseCode === 1) {
          setFollowers(response.data.ResponseData.follower);
        } else {
          console.error('Error fetching followers:', response.data.ResponseText);
        }
      } catch (error) {
        console.error('Error fetching followers:', error);
      }
    };

    fetchFollowers();
  }, []);

  return (
    <div className="mb-3">
      <div className="section-title mb-0">
        <h4 className="m-0 text-uppercase font-weight-bold">Follow Us</h4>
      </div>
      <div className="bg-white border border-top-0 p-3">
        {followers.map((follower, index) => (
          <a key={follower.id} href={follower.url} className="d-flex align-items-center  w-100 text-white text-decoration-none mb-3 p-2" style={{ background: colors[index % colors.length] }}>
             <div style={{ width: 50, height: 50 }}> 
            <img src={follower.image} alt={follower.name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
           </div>
            <span className="font-weight-medium ml-3">{follower.name} - {follower.followers} Followers</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Follow;
