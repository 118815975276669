import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Tags = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await axios.get(process.env.REACT_APP_BLOG_URL + 'home-data');
        if (response.data.ResponseCode === 1) {
          setCategories(response.data.ResponseData.category);
        } else {
          console.error('Error fetching data:', response.data.ResponseText);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchCategories();
  }, []);
  return (
   <>
   <div>
        {/* Tags Start */}
        <div className="mb-3">
          <div className="section-title mb-0">
            <h4 className="m-0 text-uppercase font-weight-bold">Tags</h4>
          </div>
          <div className="bg-white border border-top-0 p-3">
            <div className="d-flex flex-wrap m-n1">
              {categories.map(category => (
                <a href="#" className="btn btn-sm btn-outline-secondary m-1" key={category.id}>{category.name}</a>
              ))}
            </div>
          </div>
        </div>
        {/* Tags End */}
      </div>
   </>
  )
}

export default Tags
