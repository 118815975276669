import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CommentList = ({  comments }) => {

 
  return (
    <div className="mb-3">
      <div className="section-title mb-0">
        <h4 className="m-0 text-uppercase font-weight-bold">Comments ({comments.length})</h4>
      </div>
      <div className="bg-white border border-top-0 p-4">
        {comments.map(comment => (
          <div key={comment.id} className="media mb-4">
            <img src="../assets/img/user.jpg" alt="Image" className="img-fluid mr-3 mt-1" style={{ width: 45 }} />
            <div className="media-body">
              <h6><a className="text-secondary font-weight-bold" href={comment.website}>{comment.name}</a> <small><i>{new Date(comment.created_at).toDateString()}</i></small></h6>
              <p>{comment.msg}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentList;
